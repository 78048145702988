import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        borderBottom: '1px solid rgba(224, 224, 224, 0.6)',
        padding: '12px 16px',
        lineHeight: 1.25,
        color: '#535353'
    },
    [`&.${tableCellClasses.body}`]: {
        borderBottom: '1px solid rgba(224, 224, 224, 0.6)',
        padding: '12px 16px',
        lineHeight: 1.25,
        color: '#535353'
    }
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    }
}));

const tooltip = (title) => (
    <Tooltip title={title}>
        <IconButton
            sx={{
                height: '12px',
                width: '12px',
                marginLeft: '8px',
                // color: '#008AC9',
                color: '#d9ab5a'
            }}
        >
        <InfoIcon fontSize='small' />
        </IconButton>
    </Tooltip>
);

const Table1 = () => {
    function createData(name, price, tooltip) {
        return { name, price, tooltip };
    }

    const rows = [
        createData('Konsultacja - dr Kamil Stępkowski', 200, false),
        createData('USG doppler tętnic szyjnych', 170, false),
        createData('USG doppler kończyn dolnych (jedna noga)', 170, false),
        createData('USG doppler kończyn dolnych (dwie nogi)', 250, false),
        createData('Skleroterapia', 900, false),
        createData('Miniflebektomia', '1500 - 2000', false),
        createData('Laserowe usuwanie żylaków (ELVeS) - wewnątrzżylne usuwanie żył', 'od 4000', 'Dokładną cenę ustala lekarz.')
    ];

    return (
        <TableContainer className='table-container' component={Paper} >
            <Table size='small' aria-label='customized table'>
                <TableHead>
                    <TableRow>
                    <StyledTableCell>
                        Nazwa
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                        Cena
                    </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell component='th' scope='row'>
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align='right' className='nowrap'>
                                {row.price}
                                {row.tooltip && tooltip(row.tooltip)}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const PricesVascularSurgeryPartial = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    return (
        <Box display='grid' gap={'15px'}>
            <Table1 />
        </Box>
    );
};

export default PricesVascularSurgeryPartial;
