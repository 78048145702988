import ninaWisniewskaImage from '../assets/team/ninaWisniewska/ninaWisniewskaImage.jpg';
import paulinaSotImage from '../assets/team/paulinaSot/paulinaSotImage.jpg';
import konradKochanImage from '../assets/team/konradKochan/konradKochanImage.jpg';
import wojciechKaszewskiImage from '../assets/team/wojciechKaszewski/wojciechKaszewskiImage.jpg';
import justynaKlonieckaImage from '../assets/team/justynaKloniecka/justynaKlonieckaImage.jpg';
import katarzynaMysliwiecCzajkaImage from '../assets/team/katarzynaMysliwiecCzajka/katarzynaMysliwiecCzajkaImage.jpg';
import klaudiaMalottkiImage from '../assets/team/klaudiaMalottki/klaudiaMalottkiImage.jpg';
import aldonaMaciagImage from '../assets/team/aldonaMaciag/aldonaMaciagImage.jpg';
import milenaJuzwiakImage from '../assets/team/milenaJuzwiak/milenaJuzwiakImage.jpg';
import karinaKwiatkowskaImage from '../assets/team/karinaKwiatkowska/karinaKwiatkowskaImage.jpg';
import katarzynaZbronskaImage from '../assets/team/katarzynaZbronska/katarzynaZbronskaImage.jpg';
import tomaszMatuszewskiImage from '../assets/team/tomaszMatuszewski/tomaszMatuszewskiImage.jpg';
import kamilStepkowskiImage from '../assets/team/kamilStepkowski/kamilStepkowskiImage.jpg';
import olgaLenartowiczWarakomskaImage from '../assets/team/olgaLenartowiczWarakomska/olgaLenartowiczWarakomskaImage.jpg';
import paulinaTataraImage from '../assets/team/paulinaTatara/paulinaTataraImage.jpg';
import joannaOjrzenskaImage from '../assets/team/joannaOjrzenska/joannaOjrzenskaImage.jpg';
import agataSkwerekSzewczykImage from '../assets/team/agataSkwerekSzewczyk/agataSkwerekSzewczykImage.jpg';
import katarzynaRychlikImage from  '../assets/team/katarzynaRychlik/katarzynaRychlikImage.jpg';
import patrykKrokwinskiImage from  '../assets/team/patrykKrokwinski/patrykKrokwinskiImage.jpg';
import michalRadzioImage from  '../assets/team/michalRadzio/michalRadzioImage.jpg';
import kajaStrojnowskaLapinskaImage from  '../assets/team/kajaStrojnowskaLapinska/kajaStrojnowskaLapinskaImage.jpg';
import jadwigaDowgialloSmolarczykImage from  '../assets/team/jadwigaDowgialloSmolarczyk/jadwigaDowgialloSmolarczykImage.jpg';
import karolinaZegotaImage from '../assets/team/karolinaZegota/karolinaZegotaImage.jpg';
import hannaAndrusevichImage from '../assets/team/hannaAndrusevich/hannaAndrusevichImage.jpg';
// import teamBackground from '../assets/team/teamBackground.jpg';

export const ninaWisniewska = {
    id: '4d6a6ec4-ea93-40b5-9d23-cb7204c6911e',
    specialist: 'nina-wisniewska',
    name: 'dr n. med. Nina Wiśniewska',
    speciality: 'Specjalista w zakresie dermatologii i wenerologii',
    summary: 'Urodziłam się w Wyszkowie i dlatego to miejsce jest szczególnie mi bliskie. Od kilku lat przyjmuję tu jako dermatolog pomagając Państwu w zakresie chorób skóry i to dzięki Państwa inicjatywie poszerzam swoją działalność. Zajmuję się kompleksowo leczeniem wszystkich chorób skóry. Przypadki wymagające szczegółowej diagnostyki kieruję do Kliniki Dermatologicznej WIM MON przy ulicy Szaserów 128 w Warszawie, gdzie pełnię funkcję starszego asystenta. W zakresie medycyny estetycznej stale podnoszę swoje kwalifikacje, uczestnicząc w licznych szkoleniach i kongresach, m.in. w zakresie laseroterapii oraz zabiegów z zastosowaniem wypełniaczy. W swojej pracy stawiam głównie na naturalność.',
    education: 'Dr n. med. Nina Wiśniewska jest absolwentką I Wydziału Lekarskiego Akademii Medycznej w Warszawie. Na swoim koncie posiada wiele publikacji naukowych oraz wystąpień na konferencjach i sympozjach polskich i zagranicznych. Czynnie bierze udział w wystąpieniach telewizyjnych takich jak "Pytanie na śniadanie", "Dzień Dobry Polsko", "Sekrety lekarzy" i innych. Jej publikacje dostępne są w czasopismach branżowych "Twój Styl", "Flesz", "Party", "Gala" i innych.',
    experience: 'Nagrodzona przez Ministra Zdrowia w 2015 roku za uzyskanie najlepszego wyniku w Polsce z egzaminu PES w zakresie dermatologii i wenerologii. Na co dzień pełni funkcję Starszego Asystenta w Klinice Dermatologicznej Wojskowego Instytutu Medycznego Ministerstwa Obrony Narodowej w Warszawie. Członkini  Polskiego Towarzystwa Dermatologicznego i Farmaekonomicznego. Członkini Polskiego Towarzystwa Medycyny Estetycznej i Anti-Anging.',
    interested: false,
    image: ninaWisniewskaImage,
    background: null,
    specialization: [
        'dermatology',
        // 'aesthetic-medicine'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        // 'modelowanie-ust',
        // 'toksyna-botulinowa',
        // 'mezoterapia-iglowa',
    ],
    career: [
        'dermatologist'
    ]
};

export const paulinaSot = {
    id: '96ecd64c-5904-4205-b97a-48b7f8b4b4ff',
    specialist: 'paulina-sot',
    name: 'mgr Paulina Sot',
    speciality: 'Kosmetolog',
    summary: false,
    education: 'Absolwentka studiów magisterskich na kierunku kosmetologia. Od początku swojej kariery zawodowej stawia na profesjonalizm oraz indywidualne podejście do pacjenta. Dokłada wszelkich starań, aby każdy kto korzysta z jej usług mógł poczuć się wyjątkowo.',
    experience: 'Specjalizuje się w wykonywaniu zabiegów pielęgnacyjno-leczniczych, w szczególności dla skór wymagających, tj. skóra trądzikowa, z przebarwieniami, bliznami potrądzikowymi, itp. Ponadto wykonuje zabiegi z zakresu ujędrniania i modelowania sylwetki, a także zabiegi epilacji laserowej. Chętnie uczy się nowych rzeczy. Swoją wiedzę pogłębia uczestnicząc w specjalistycznych szkoleniach i kongresach. Bardzo ceni sobie wzajemną współpracę z lekarzami z dziedziny dermatologii i medycyny estetycznej. Z Kliniką dr Niny Wiśniewskiej związana jest od początku jej istnienia.',
    interested: false,
    image: paulinaSotImage,
    background: null,
    specialization: [
        'cosmetology'
    ],
    treatment: [
        'peeling-medyczny',
        'epilacja-laserowa',
        'masaz-prozniowy',
        'ultradzwieki-i-fala-radiowa',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien'
    ],
    career: [
        'cosmetologist'
    ]
};

export const konradKochan = {
    id: 'c8b0fa10-c635-402e-9a5d-8cc5b7dc8d7a',
    specialist: 'konrad-kochan',
    name: 'lek. med. Konrad Kochan',
    speciality: 'Specjalista chirurgii plastycznej, certyfikowany lekarz medycyny estetycznej',
    summary: false,
    education: 'Ukończył z wyróżnieniem Wydział Lekarski Akademii Medycznej PAM. Uzyskał stypendium naukowe od Ministra Zdrowia prof. Zbigniewa Religi. Specjalizację z chirurgii plastycznej ukończył we Wschodnim Centrum Leczenia Oparzeń i Chirurgii Rekonstrukcyjnej.',
    experience: 'Jest absolwentem Podyplomowej Szkoły Medycyny Estetycznej Polskiego Towarzystwa Lekarskiego w Warszawie oraz lekarzem American Academy of Aesthetic Medicine. Od 2012 zajmuje się medycyną estetyczną oraz chirurgią plastyczną. Był uczestnikiem wielu kursów i kongresów z zakresu chirurgii plastycznej oraz medycyny estetycznej organizowanych zarówno w kraju jak i za granicą. Był wykładowcą oraz doktorantem I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego (WUM). Swoje doświadczenie uzupełniał w ramach licznych praktyk, również zagranicznych: w Klinice chirurgii University of Palermo P.Giaccone Hospital, Italy oraz w Paryżu. Jest autorem publikacji i doniesień zjazdowych z zakresu chirurgii plastycznej. Jest członkiem Polskiego Towarzystwa Chirurgii Plastycznej, Rekonstrukcyjnej i Estetycznej oraz Polskiego Towarzystwa Leczenia Oparzeń. Specjalizuje się w zabiegach z zakresu medycyny estetycznej twarzy, chirurgii plastycznej piersi, plastyki brzucha oraz liposukcji i modelowania ciała. Łączy w sobie umiejętności z zakresu medycyny estetycznej i chirurgii plastycznej.',
    interested: false,
    image: konradKochanImage,
    background: null,
    specialization: [
        'aesthetic-medicine',
        'cosmetic-surgery'
    ],
    treatment: [
        'plastyka-powiek'
    ]
};

export const agataSkwerekSzewczyk = {
    id: '6fb054ca-430a-488d-8c43-31bf5f58911c',
    specialist: 'agata-skwerek-szewczyk',
    name: 'lek. med. Agata Skwerek Szewczyk',
    speciality: 'Reumatolog',
    summary: false,
    education: 'Specjalista Chorób Wewnętrznych i Reumatologii. Absolwentka Warszawskiego Uniwersytetu Medycznego i Wydziału Zarządzania Uniwersytetu Warszawskiego. W 2016 roku zdałam egzamin specjalizacyjny w zakresie Chorób Wewnętrznych.',
    experience: 'Od 2011 pracuję jako lekarz, obecnie starszy asystent w Klinice Chorób Wewnętrznych i Endokrynologii Centralnego Szpitala Klinicznego Warszawskiego Uniwersytetu Medycznego przy ul. Banacha. Jestem współautorem wielu publikacji w zakresie zaburzeń gospodarki wapniowo-fosforanowej. Od 2019 r. jestem również specjalistą Reumatologiem. Specjalizację odbywałam w Klinice Wczesnego Zapalenia Stawów Narodowego Instytutu Reumatologii i Geriatrii w Warszawie. Prywatnie żona i mama 2 wspaniałych chłopców.',
    interested: false,
    image: agataSkwerekSzewczykImage,
    background: null,
    specialization: [
        'rheumatology'
    ],
    treatment: ['']
};

export const aldonaMaciag = {
    id: 'c7a059ad-ba38-4f23-8e86-6cae2c0d7990',
    specialist: 'aldona-maciag',
    name: 'lek. med. Aldona Maciąg',
    speciality: 'Dermatolog',
    summary: false,
    education: 'Absolwentka II Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego, ponadto odbywała praktyki w Klinice Dermatologii Uniwersytetu w Kolonii. Pracuje jako młodszy asystent w Klinice Dermatologicznej Wojskowego Instytutu Medycznego w Warszawie. Jest członkinią Sekcji Dermatologii Onkologicznej Polskiego Towarzystwa Dermatologicznego. Stale podnosi swoje kwalifikacje zawodowe aktywnie uczestnicząc w polskich i zagranicznych konferencjach i szkoleniach.',
    experience: 'Szczególnym obszarem jej zainteresowań jest dermatologia onkologiczna i dermatochirurgia. Największą satysfakcję przynosi jej wczesne wykrywanie i skuteczne leczenie nowotworów skóry z optymalnym efektem estetycznym.',
    interested: false,
    image: aldonaMaciagImage,
    background: null,
    specialization: [
        'dermatology'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        'usuniecie-zmian-skornych-elektrochirurgiczne'
    ],
    career: [
        'dermatologist'
    ]
};

export const justynaKloniecka = {
    id: 'e6b6ea1e-7dfe-46b3-a533-7bb87816d799',
    specialist: 'justyna-kloniecka',
    name: 'lek. med. Justyna Kłoniecka',
    speciality: 'Dermatolog',
    summary: false,
    education: 'Absolwentka Wydziału Wojskowo-Lekarskiego Uniwersytetu Medycznego w Łodzi oraz Akademii Wojsk Lądowych we Wrocławiu. Szkolenie specjalizacyjne z dermatologii i wenerologii odbywa w Klinice Dermatologicznej CSK MON Wojskowego Instytutu Medycznego w Warszawie.',
    experience: 'Zajmuje się diagnostyką i leczeniem chorób skóry, włosów i paznokci oraz chorób przenoszonych drogą płciową. Stale podnosi swoje kwalifikacje uczestnicząc w licznych kursach i konferencjach związanych z dermatologią kliniczną. W swojej codziennej pracy zawodowej ceni holistyczne i indywidualne podejście do każdego pacjenta w myśl zasady "Salus aegroti suprema lex" dobro chorego najwyższym prawem.',
    interested: false,
    image: justynaKlonieckaImage,
    background: null,
    specialization: [
        'dermatology'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        'usuniecie-zmian-skornych-elektrochirurgiczne'
    ],
    career: [
        'dermatologist'
    ]
};

export const klaudiaMalottki = {
    id: '9d4116c1-c755-46f8-85d5-65c8377abd21',
    specialist: 'klaudia-malottki',
    name: 'lek. dent. Klaudia Malottki',
    speciality: 'Dyplomowany lekarz medycyny estetycznej',
    summary: 'Proporcje, symetria i detale to coś co często nie pozwala mi zmrużyć oka. Uwielbiam poprawiać urodę i wydobywać z niej jak najwiecej, ale najważniejsze jest dla mnie żeby nie zaburzyć naturalnego piękna każdego pacjenta. W codziennej pracy narzędziem bez którego nie wyobrażam sobie zabiegów jest kredka zaplanowanie i sposób przekazania tego pacjentom jest drogą do porozumienia.',
    education: 'Ukończyła Uniwersytet Medyczny im Piastów Śląskich we Wrocławiu na kierunku lekarski dentystycznym. Następnie studia podyplomowe Medycyna Estetyczna na Warszawskim Uniwersytecie Medycznym.',
    experience: 'Z zawodu stomatolog, z zamiłowania poszukiwacz piękna i estetyki zarówno w uśmiechu jak i całej twarzy.',
    interested: false,
    image: klaudiaMalottkiImage,
    background: null,
    specialization: [
        'aesthetic-medicine'
    ],
    treatment: [
        'modelowanie-ust',
        'toksyna-botulinowa',
        'stymulatory-tkankowe',
        'osocze-bogatoplytkowe',
        'mezoterapia-iglowa',
        'nici-rewitalizujace-i-liftingujace',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien',
        'sonoqueen-technologia-hifu'
    ]
};

export const katarzynaMysliwiecCzajka = {
    id: 'katarzynaMysliwiecCzajka',
    specialist: 'katarzyna-mysliwiec-czajka',
    name: 'lek. med. Katarzyna Myśliwiec-Czajka',
    speciality: 'Dermatolog',
    summary: false,
    education: 'Absolwentka I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego, który ukończyła otrzymując dyplom z wyróżnieniem.',
    experience: 'Obecnie pracuje w Klinice Dermatologicznej Wojskowego Instytutu Medycznego w Warszawie, gdzie odbywa staż specjalizacyjny z dermatologii i wenerologii. Stale poszerza swoje kwalifikacje biorąc udział w licznych certyfikowanych kursach, szkoleniach i konferencjach. Zajmuje się diagnostyką oraz leczeniem chorób skóry, włosów i paznokci.',
    interested: false,
    image: katarzynaMysliwiecCzajkaImage,
    background: null,
    specialization: [
        'dermatology'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        'usuniecie-zmian-skornych-elektrochirurgiczne',
    ],
    career: [
        'dermatologist'
    ]
};

export const milenaJuzwiak = {
    id: '39323729-937c-403a-b16b-a8e964965288',
    specialist: 'milena-juzwiak',
    name: 'mgr Milena Juźwiak',
    speciality: 'Fizjoterapeuta',
    summary: false,
    education: false,
    experience: 'Fizjoterapeuta z ponad kilkuletnim doświadczeniem zawodowym. Specjalizuje się w rehabilitacji ortopedycznej, neurologicznej, a także masażu. Jej zainteresowania zawodowe skupiają się na diagnostyce fizjoterapeutycznej, terapii pooperacyjnej, terapii poudarowej oraz profilaktyce zdrowotnej. Z wielką przyjemnością łączy w swojej pracy pasję oraz wiedzę zdobytą na specjalistycznych kongresach i szkoleniach.',
    interested: false,
    image: milenaJuzwiakImage,
    background: null,
    specialization: [
        'physioterapy'
    ],
    treatment: [
        'rehabilitacja',
        'masaz',
        'kinesiotaping'
    ]
};

export const karinaKwiatkowska = {
    id: '374db991-54bf-4079-ab32-3cff83245431',
    specialist: 'karina-kwiatkowska',
    name: 'mgr Karina Kwiatkowska',
    speciality: 'Fizjoterapeuta',
    summary: false,
    education: 'Jestem magistrem fizjoterapii oraz masażystką z zamiłowaniem i pasją do poprawy wyglądu oraz samopoczucia innych. Szczególnym zainteresowaniem darzę pracę z ludzką twarzą oraz szyjnym odcinkiem kręgosłupa. Swoje wykształcenie zawdzięczam studiom na kierunku "Fizjoterapia" w Wyższej Szkole Rehabilitacji w Warszawie, oraz licznym kursom takim jak kurs masażu Kobido Lift, masażu Natural Face Lifting, oraz kursu z Fizjoterapii Estetycznej Twarzy.',
    experience: 'Swoją pracą dążę do poprawy samopoczucia i samoakceptacji u moich klientów, dlatego stale poszerzam swoją wiedzę z dziedziny masażu i fizjoterapii. Wierzę, że masaż nie tylko potrafi rozluźnić nasze mięśnie i zrelaksować, ale również sprawić, że nasza twarz nabierze promiennego wyglądu, będzie wyglądała młodziej, o czym przekonały się już klientki, które zdecydowały się wykonać u mnie masaże twarzy.',
    interested: false,
    image: karinaKwiatkowskaImage,
    background: null,
    specialization: [
        'physioterapy'
    ],
    treatment: [
        'masaz-kobido',
        'masaz-natural-face-lifting'
    ]
};

export const paulinaTatara = {
    id: '3b2ca017-a5cd-4d1d-a8dd-6a4dc84b21a2',
    specialist: 'paulina-tatara',
    name: 'lek. med. Paulina Tatara',
    speciality: 'Dermatolog, lekarz medycyny estetycznej',
    summary: 'Od zawsze chciałam, by mój zawód polegał na niesieniu pomocy innym. Będąc dermatologiem mogę wspierać pacjentów w dążeniu do samoakceptacji i w budowaniu pewności siebie. Oprócz leczenia, lubię także edukować. Wychodzę z założenia, że dobre zdrowie zaczyna się od wiedzy. Realizuję swoje poczucie estetyki i empatii kształcąc się na kursach i szkoleniach z zakresu medycyny estetycznej. Uważam, że mniej znaczy więcej, a zabiegi z zakresu medycyny estetycznej powinny podkreślać atuty, nie zmieniać. Naturalne piękno po prostu potrzebuje czasami kropki nad i a nie zmiany całego wersu.',
    education: 'Absolwentka Wydziału Wojskowo-Lekarskiego Uniwersytetu Medycznego w Łodzi. Licencjatka neurobiologii na Uniwersytecie, gdzie uzyskała dyplom ratownika medycznego. Nieustannie poszerza wiedzę, dokształca się i bierze udział w szkoleniach, by sprostać oczekiwaniom pacjentów. Aktualnie jest w trakcie specjalizacji z Dermatologii i Wenerologii w Klinice Dermatologicznej Wojskowego Instytutu Medycznego w Warszawie. Szerokie, a zarazem bezpośrednio związane z medycyną zainteresowania, pozwoliły jej po ukończeniu studiów medycznych uzyskać tytuł Lidera Lekarskiego Egzaminu Końcowego. W swojej pracy zawodowej obejmowała również etat lekarza polskiego kontyngentu wojskowego PKW Orlik w Estonii.',
    experience: false,
    interested: 'Jestem miłośniczką kryminałów i wyzwań, przede wszystkim sportowych. Sport uczy mnie konsekwencji w dążeniu do celu. Na swoim koncie mam ukończenie zawodów triathlonowych na dystansie pełnego Ironmana.',
    image: paulinaTataraImage,
    background: null,
    specialization: [
        'dermatology',
        'aesthetic-medicine'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        'usuniecie-zmian-skornych-elektrochirurgiczne',
        'modelowanie-ust',
        'toksyna-botulinowa',
        'stymulatory-tkankowe',
        'mezoterapia-iglowa',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien'
    ],
    career: [
        'dermatologist'
    ]
};

export const tomaszMatuszewski = {
    id: '51afb769-17b6-4f91-815e-46b7a9d2090e',
    specialist: 'tomasz-matuszewski',
    name: 'dr n. med. Tomasz Matuszewski',
    speciality: 'Alergolog, pediatra',
    summary: false,
    education: 'Absolwent Wydziału Lekarskiego Wojskowej Akademii Medycznej w Łodzi. Na co dzień pracuje w Poradni Alergologii i Immunologii przy Klinice Chorób Wewnętrznych, Pneumonologii, Alergologii i Immunologii Klinicznej Wojskowego Instytutu Medycznego w Warszawie.',
    experience: 'Przewodniczący Sekcji Obrzęku Wrodzonego przy Polskim Towarzystwie Alergologicznym. Posiada kwalifikacje w diagnostyce i leczeniu chorób alergologicznych u dzieci i dorosłych. Wykonuje testy skórne, prowadzi immunoterapię, zajmuje się między innymi leczeniem pokrzywek, astmy oskrzelowej, atopowego zapalenia skóry. Świadczy porady z zakresu chorób zakaźnych tj, borelioza, choroby pasożytnicze, choroby zakaźne skóry, HCV oraz z zakresu medycyny podróży. Zainteresowania swoje poszerza również w zakresie medycyny estetycznej gdzie zdobywa doświadczenie pod okiem najlepszych specjalistów. Autor i współautor licznych publikacji z dziedziny alergologii czy pediatrii w recenzowanych czasopismach. Aktywny uczestnik kongresów alergologicznych, medycyny podróży.',
    interested: 'Interesuję się kulinariami, enoturystyką.',
    image: tomaszMatuszewskiImage,
    background: null,
    specialization: [
        'allergology'
    ],
    treatment: [
        'testy-skorne',
        'platkowe-testy-kontaktowe',
        'odczulanie'
    ]
};

export const olgaLenartowiczWarakomska = {
    id: 'eea454b7-6f22-401b-93b7-c29b166751d9',
    specialist: 'olga-lenartowicz-warakomska',
    name: 'lek. dent. Olga Lenartowicz-Warakomska',
    speciality: 'Stomatolog, specjalista medycyny estetycznej',
    summary: false,
    education: 'Stomatologia była jej marzeniem od dzieciństwa, zaś w trakcie studiów zauroczyła się medycyna estetyczną. Ukończyła Uniwersytet Medyczny w Lublinie w 2016 roku. Praca w zawodzie przyniosła zainteresowanie chirurgią stomatologiczną, periodontologią i okluzją, a także stomatologią estetyczną oraz medycyną estetyczną. Pasja jaką jest estetyka zaowocowała ukończeniem w 2019 roku studiów podyplomowych z zakresu medycyny estetycznej w Krakowie.',
    experience: 'Leczenie kompleksowe zaawansowanych przypadków chorób oraz łączenie stomatologii z medycyną estetyczną przynosi jej największą satysfakcję. Jest bardzo zaangażowana w swoją pracę. Problem pacjenta stara się zawsze widzieć w jak najszerszej perspektywie, aby podczas leczenia najlepiej wykorzystać wszystkie osiągnięcia nowoczesnej stomatologii oraz medycyny estetycznej. Nieustannie uczestniczy w kursach dokształcających, konferencjach naukowych oraz śledzi prasę specjalistyczną ze wszystkich dziedzin związanych z zainteresowaniami zawodowymi. Jest conkiem Polskiego Towarzystwa Medycyny Estetycznej i Anti-Aging (PTMEIAA) oraz Polskiego, Polskiego Towarzystwa Lekarzy Medycyny Estetycznej POLME, Towarzystwa Stomatologicznego PTS.',
    interested: 'W wolnej chwili jeżdżę na rowerze, na snowboardzie, żegluję, podróżuję i czytam książki.',
    image: olgaLenartowiczWarakomskaImage,
    background: null,
    specialization: [
        'aesthetic-medicine'
    ],
    treatment: [
        'modelowanie-ust',
        'toksyna-botulinowa',
        'stymulatory-tkankowe',
        'osocze-bogatoplytkowe',
        'mezoterapia-iglowa',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien',
        'sonoqueen-technologia-hifu'
    ]
};

export const katarzynaZbronska = {
    id: 'e48dc37a-0c66-4749-986c-f180849dc44c',
    specialist: 'katarzyna-zbronska',
    name: 'lek. med. Katarzyna Zbrońska',
    speciality: 'Dermatolog, trycholog',
    summary: false,
    education: 'Absolwentka I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego. Obecnie pracuje w Klinice Dermatologicznej Wojskowego Instytutu Medycznego w Warszawie. Jest członkiem Sekcji Dermatologii Onkologicznej Polskiego Towarzystwa Dermatologicznego oraz International Trichoscopy Society.',
    experience: 'Do jej głównych zainteresowań zawodowych należą choroby skóry owłosionej, diagnostyka trichoskopowa i leczenie różnych typów łysienia przy użyciu odpowiednio dobranych technik medycyny regeneracyjnej. Doświadczenie w zakresie medycyny estetycznej zdobywała pod okiem najlepszych specjalistów, a swoje kwalifikacje stale poszerza biorąc udział w licznych kursach, szkoleniach i kongresach. Podczas planowania leczenia stawia na subtelność i naturalne podkreślenie indywidualnych atutów. Poza zabiegami z użyciem toksyny botulinowej, kwasu hialuronowego i laseroterapii, zajmuje się usuwaniem znamion metodą dermatochirurgiczną. Specjalizuje się również w wykonywaniu zabiegów z użyciem osocza bogatopłytkowego oraz technologii HIFU-SonoQueen.',
    interested: false,
    image: katarzynaZbronskaImage,
    background: null,
    specialization: [
        'dermatology',
        'aesthetic-medicine'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory',
        'usuniecie-zmian-skornych-elektrochirurgiczne',
        'modelowanie-ust',
        'toksyna-botulinowa',
        'stymulatory-tkankowe',
        'osocze-bogatoplytkowe',
        'mezoterapia-iglowa',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien',
        'sonoqueen-technologia-hifu'
    ],
    career: [
        'dermatologist'
    ]
};

export const joannaOjrzenska = {
    id: '45cfadf1-1d6c-42be-96e2-b8ddd6469a10',
    specialist: 'joanna-ojrzenska',
    name: 'mgr Joanna Ojrzeńska',
    speciality: 'Dietetyk kliniczny',
    summary: false,
    education: false,
    experience: 'Absolwentka Warszawskiego Uniwersytetu Medycznego. W swoich działaniach skupia się na indywidualnej współpracy z pacjentem oraz rozpowszechnianiu wiedzy na temat zdrowego stylu życia. Pokazuje, że odchudzanie nie musi być męczarnią, a zdrowe odżywianie może być proste i smaczne.',
    interested: false,
    image: joannaOjrzenskaImage,
    background: null,
    specialization: [
        'clinical-dietitian'
    ],
    treatment: ['']
};

export const katarzynaRychlik = {
    id: 'e78f0fef-0597-4ef4-b741-a576b0a86342',
    specialist: 'katarzyna-rychlik',
    name: 'lek. med. Katarzyna Rychlik',
    speciality: 'Dermatolog',
    summary: false,
    education: 'Absolwentka I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego.',
    experience: 'Obecnie pracuje w Klinice Dermatologicznej Wojskowego Instytutu Medycznego w Warszawie, gdzie odbywa staż specjalizacyjny z dermatologii i wenerologii. Zajmuje się diagnostyką oraz leczeniem chorób skóry, włosów i paznokci. Stale podnosi swoje kwalifikacji zawodowe aktywnie uczestnicząc w licznych konferencjach i szkoleniach.',
    interested: false,
    image: katarzynaRychlikImage,
    background: null,
    specialization: [
        'dermatology'
    ],
    treatment: [
        'badanie-znamion-barwnikowych',
        'fototerapia-chorob-skory'
    ],
    career: [
        'dermatologist'
    ]
};

export const michalRadzio = {
    id: '19cf6af2-bb99-4c99-8e2f-9c6090c55c8f',
    specialist: 'michal-radzio',
    name: 'lek. med. Michał Radzio',
    speciality: 'Specjalista chorób wewnętrznych i kardiologii',
    summary: false,
    education: 'Absolwent I Wydziału Lekarskiego Akademii Medycznej w Warszawie.',
    experience: 'Lek. med. Michał Radzio na co dzień zajmuje się diagnostyką i leczeniem szerokiej gamy schorzeń z zakresu chorób wewnętrznych oraz chorób układu krążenia. Od 1999 roku pracuje na Oddziale Kardiologicznym oraz w Poradni Kardiologicznej przyszpitalnej SPZZOZ w Wyszkowie. Obecnie pełni również funkcję zastępcy ordynatora oddziału Kardiologicznego w Wyszkowie. Prócz konsultacji kardiologicznych świadczy usługi badania echokardiografii przezklatkowej oraz EKG spoczynkowe z opisem.',
    interested: false,
    image: michalRadzioImage,
    background: null,
    specialization: [
        'cardiology'
    ],
    treatment: [
        'echo-serca'
    ]
};

export const kajaStrojnowskaLapinska = {
    id: 'cef1384b-6491-4af4-b4ef-d7ffb1e2ed31',
    specialist: 'kaja-strojnowska-lapinska',
    name: 'lek. Kaja Strojnowska-Łapińska',
    speciality: 'Psychiatra dzieci i młodzieży',
    summary: 'Jestem lekarzem w trakcie specjalizacji z psychiatrii dzieci i młodzieży w Klinice Psychiatrii Wieku Rozwojowego Dziecięcego Szpitala Klinicznego Warszawskiego Uniwersytetu Medycznego.',
    education: 'Ukończyłam II Wydział Lekarski Warszawskiego Uniwersytetu Medycznego. Interesuję się również psychoterapią. Uczestniczyłam w szkoleniu podyplomowym "Psychoterapia poznawczo-behawioralna z elementami terapii psychodynamicznej" w Fundacji Rozwoju Psychiatrii i Psychoterapii oraz w szkoleniu w zakresie terapii poznawczo-behawioralnej w Szkole Terapii Poznawczo-Behawioralnej Centrum CBT-EDU. Umiejętności z zakresu psychoterapii dorosłych zdobywałam również w trakcie stażu na Oddziale Dziennym Mazowieckiego Szpitala Bródnowskiego.',
    experience: false,
    interested: false,
    image: kajaStrojnowskaLapinskaImage,
    background: null,
    specialization: [
        'psychiatry'
    ],
    treatment: [
        'badanieAdos2'
    ],
    career: [
        'psychiatrist'
    ]
};

export const jadwigaDowgialloSmolarczyk = {
    id: 'e116795b-e60e-45d9-9fce-d7c835a67e73',
    specialist: 'jadwiga-dowgiallo-smolarczyk',
    name: 'dr n. med. Jadwiga Dowgiałło-Smolarczyk',
    speciality: 'Specjalista ginekologii i endokrynologii ',
    summary: false,
    education: 'Jestem absolwentką I Wydziału Lekarskiego Akademii Medycznej w Warszawie. Uzyskałam specjalizację w zakresie położnictwa, ginekologii i endokrynologii. W 2002 roku obroniłam pracę doktorską, otrzymując tytuł doktora nauk medycznych.',
    experience: 'Doświadczenie zawodowe zawdzięczam pracy w Klinice Położnictwa i Ginekologii Akademii Medycznej i w Klinice Endokrynologii Ginekologicznej Akademii Medycznej w Warszawie. Stale podnoszę kwalifikacje zawodowe w zakresie diagnostyki i leczenia niepłodności, opieki perinatalnej, diagnostyki i leczenia schorzeń ginekologicznych, w tym zaburzeń endokrynologicznych i ultrasonografii ginekologicznej. Uczestniczę regularnie w kursach, warsztatach i konferencjach. Znam biegle język angielski i francuski. Mogę się również porozumieć  po włosku i po rosyjsku.',
    interested: 'Moje pasje pozazawodowe to narciarstwo i windsurfing. Jestem wielokrotną medalistką Mistrzostw Świata Lekarzy, Mistrzostw Polski Lekarzy i Mistrzostw Polski Amatorów w narciarstwie alpejskim.',
    image: jadwigaDowgialloSmolarczykImage,
    background: null,
    specialization: [
        'gynecology',
        'endocrinology',
        'usg'
    ],
    treatment: ['']
};

export const karolinaZegota = {
    id: '8d18264e-742e-4aa6-9d19-ee70f40aef69',
    specialist: 'karolina-zegota',
    name: 'Karolina Zęgota',
    speciality: 'Kosmetolog',
    summary: false,
    education: 'Ukończyła studia licencjackie na kierunku kosmetologia i dalej podąża tą ścieżką edukacji. W swoim zawodzie zdecydowanie kładzie nacisk na samopoczucie pacjenta oraz pomoc mu poprzez holistyczne podejście współpracując z lekarzami. Posiada świetne zdolności komunikacyjne i empatię, które pozwalają jej na zrozumienie i spełnienie potrzeb pacjentów.',
    experience: 'Specjalizuje się w pracy ze skórą trądzikową oraz profilaktyce przeciwstarzeniowej. Ponadto wykonuje zabiegi z zakresu modelowania sylwetki i depilacji laserowej. Na każdej płaszczyźnie w swoim zawodzie stara się być profesjonalistką ,która kieruje się indywidualnymi potrzebami pacjentów i dostarcza im kompleksową opiekę. Jej głównym celem jest poprawa wyglądu i zdrowia skóry, przy jednoczesnym zapewnieniu komfortu i zaufania pacjenta.',
    interested: false,
    image: karolinaZegotaImage,
    background: null,
    specialization: [
        'cosmetology'
    ],
    treatment: [
        'peeling-medyczny',
        'epilacja-laserowa',
        'masaz-prozniowy',
        'ultradzwieki-i-fala-radiowa',
        'laser-frakcyjny',
        'zamykanie-naczynek-i-likwidacja-przebarwien'
    ],
    career: [
        'cosmetologist'
    ]
};

export const wojciechKaszewski = {
    id: 'ac4ca38b-526a-49ee-adad-5f81e0347623',
    specialist: 'wojciech-kaszewski',
    name: 'dr n. med. Wojciech Kaszewski',
    speciality: 'Ortopeda',
    summary: false,
    education: 'Ukończył studia na Wydziale Lekarskim Collegium Medicum w Bydgoszczy, Uniwersytetu Mikołaja Kopernika w Toruniu, na kierunku lekarskim. Uzyskał tytuł doktora nauk medycznych na Wydziale Lekarskim Collegium Medicum w Bydgoszczy, UMK w Toruniu w 2016 roku. Ukończył liczne kursy doszkalające z zakresu urazów narządu ruchu ze szczególnym uwzględnieniem chirurgii ręki.',
    experience: 'Aktualnie pracuje w Wojewódzkim Szpitalu Specjalistycznym we Włocławku, gdzie pełni funkcje ordynatora oddziału. Dodatkowo pracuje jako adiunkt w Wydziale Nauk o Zdrowiu Mazowieckiej Uczelni Publicznej w Płocku. Aktywnie uczestniczy w szkoleniach z zakresu traumatologii i ortopedii narządu ruchu oraz ultrasonografii narządu ruchu. Jest członkiem PTOiTr.',
    interested: false,
    image: wojciechKaszewskiImage,
    background: null,
    specialization: [
        'orthopedics',
        'usg'
    ],
    treatment: ['']
};

export const hannaAndrusevich = {
    id: '1a71f4bc-6046-406c-aa6f-2a6a88d8c263',
    specialist: 'hanna-andrushevich',
    name: 'lek. Hanna Andrusevich',
    speciality: 'Lekarz w trakcie specjalizacji z ginekologii i położnictwa',
    summary: 'Obecnie pracuję w poradni przyszpitalnej oraz na Oddziale Ginekologiczno-Położniczym Szpitala w Wyszkowie, gdzie specjalizuję się w zakresie położnictwa oraz ginekologii. W swojej codziennej pracy skupiam się na prowadzeniu ciąży fizjologicznej i patologicznej, diagnostyce ultrasonograficznej oraz diagnostyce i leczeniu w ginekologii. Udzielam porad z zakresu zaburzeń narządu rodnego, antykoncepcji oraz wykonuję badania kontrolne. Poszerzam wiedzę i umiejętności w ginekologii operacyjnej oraz zabiegowej. Opiekuje się także pacjentkami borykającymi się z problemem nietrzymania moczu, z zaburzeniami statyki narządu rodnego. Zawodowo interesuję się kolposkopią, endoskopią i endokrynologią ginekologiczną.',
    education: 'Absolwentka Grodzieńskiego Uniwersytetu Medycznego, Białoruś. W 2019 uzyskałam nostryfikację dyplomu lekarza na Uniwersytecie Medycznym w Łodzi.',
    experience: 'Obszary działalności: choroby ginekologiczne, bolesne miesiączkowanie, zaburzenia miesiączkowania, menopauza, endometrioza, mięśniaki macicy, diagnostyka przesiewowa patologii szyjki macicy, ciąże fizjologiczne. Wykonywane zabiegi i badania: USG transvaginalne, USG doraźne ciąży, cytologia szyjki, zakładanie wkładek wewnątrzmacicznych Mirena, Kyleena, Levosert.',
    interested: false,
    image: hannaAndrusevichImage,
    background: null,
    specialization: [
        'gynecology',
        'usg'
    ],
    treatment: ['']
};

export const kamilStepkowski = {
    id: '0b01251b-90a9-4279-b7ee-afd83274a03e',
    specialist: 'kamil-stepkowski',
    name: 'lek. med. Kamil Stępkowski',
    speciality: 'Chirurg naczyniowy',
    summary: false,
    education: 'Absolwent Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego. Pracuje w Klinice Chirurgii Ogólnej, Endokrynologicznej i Chorób Naczyń w Centralnym Szpitalu Klinicznym Uniwersyteckiego Centrum Medycznego Warszawskiego Uniwersytetu Medycznego przy ul. Banacha 1a w Warszawie.',
    experience: 'Doktor Kamil Stępkowski jest specjalistą chirurgii naczyniowej i ogólnej. Zajmuje się diagnostyką i leczeniem chorób z zakresu chirurgii naczyniowej i ogólnej: choroby żył (zakrzepica, niewydolność żylna, żylaki kończyn dolnych, zespół pozakrzepowy), choroby tętnic (miażdżyca, niedokrwienie kończyn dolnych, niedokrwienie mózgu, zwężenia tętnic szyjnych, tętniaki aorty i innych tętnic), choroby tarczycy i przytarczyc (wole, choroba Gravesa-Basedowa, nowotwory tarczycy, pierwotna nadczynność przytarczyc). Wykonywane zabiegi i operacje: otwarte i wewnątrznaczyniowe metody leczenia niedrożnych tętnic oraz tętniaków aorty, leczenie żylaków kończyn dolnych metodami termicznymi (ablacja RF, laser ELVeS) i nietermicznymi (stripping, miniflebektomia, skleroterapia), wycięcie tarczycy i przytarczyc oraz operacje laparoskopowe.',
    interested: false,
    image: kamilStepkowskiImage,
    background: null,
    specialization: [
        'vascular-surgery',
        'usg'
    ],
    treatment: [
        'laserowe-usuwanie-zylakow',
        'skleroterapia'
    ]
};

export const patrykKrokwinski = {
    id: '0316f09f-7c5f-46df-a1cb-48289205050e',
    specialist: 'patryk-krokwinski',
    name: 'lek. med. Patryk Krokwiński',
    speciality: 'Radiolog',
    summary: false,
    education: false,
    experience: false,
    interested: false,
    image: patrykKrokwinskiImage,
    background: null,
    specialization: [
        'usg'
    ],
    treatment: ['']
};

export const team = [
    { ...ninaWisniewska },
    { ...paulinaSot },
    { ...konradKochan },
    { ...agataSkwerekSzewczyk },
    { ...aldonaMaciag },
    { ...justynaKloniecka },
    { ...joannaOjrzenska },
    { ...klaudiaMalottki },
    { ...katarzynaMysliwiecCzajka },
    { ...milenaJuzwiak },
    { ...karinaKwiatkowska },
    { ...paulinaTatara },
    { ...tomaszMatuszewski },
    { ...olgaLenartowiczWarakomska },
    { ...katarzynaZbronska },
    { ...katarzynaRychlik },
    { ...michalRadzio },
    { ...kajaStrojnowskaLapinska },
    { ...jadwigaDowgialloSmolarczyk },
    { ...karolinaZegota },
    { ...wojciechKaszewski },
    { ...hannaAndrusevich },
    { ...kamilStepkowski },
    { ...patrykKrokwinski }
];
